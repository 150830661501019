import React from "react";
import IcoBag from "../../../medias/svg/ico-bag-clickAndCollect.svg";
import Logo from "../../../medias/svg/Logo-burger-house.svg";
import Picto from "../../../medias/svg/picto-burger-house.svg";
import Button from "../../elements/Button";

export default function Header() {
	return (
		<div className='relative z-10 flex items-center justify-between py-10'>
			<div className='w-full'>
				<img
					src={Logo}
					alt='Burger House App'
					className='hidden w-64 md:block'
				/>
				<img src={Picto} alt='Burger House App' className='w-12 md:hidden' />
			</div>
			<div className='w-full text-secondary'>
				<div className='items-center justify-end hidden md:flex'>
					<img
						src={IcoBag}
						alt='click and collect icon'
						className='w-5 h-5 mr-1'
					/>
					<span>Commander votre repas en ligne</span>
				</div>
				<div className='flex items-center justify-end md:mt-5'>
					<Button className='mr-4 tracking-widest'>Inscription</Button>
					<Button className='tracking-widest' color='secondary'>
						Connexion
					</Button>
				</div>
			</div>
		</div>
	);
}
