import React from "react";
import {
	BrowserRouter,
	createBrowserRouter,
	Route,
	Routes,
} from "react-router-dom";
import HomePage from "./pages/HomePage";

export default function Router() {
	const router = createBrowserRouter([
		{
			path: "./",
			element: <HomePage />,
		},
	]);

	return (
		<BrowserRouter>
			<Routes>
				<Route router={router} path='/' element={<HomePage />} />
				<Route router={router} path='/test' element={<div>test</div>} />
			</Routes>
		</BrowserRouter>
	);
}
