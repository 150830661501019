import React from "react";
import BgFood from "../../../medias/images/background/bg-food.jpg";
import Food from "../../../medias/images/heroTop/burger-hero-top.png";
import Container from "../../elements/Container";
import Header from "./Header";

export default function HeroTop() {
	return (
		<div className='w-full bg-primary'>
			<div
				className='w-full h-full pb-10 bg-repeat lg:pb-36'
				style={{ backgroundImage: `url(${BgFood})` }}
			>
				<Container>
					<Header />
					<div className='relative w-full mt-10 md:mt-28'>
						<img
							src={Food}
							className='absolute right-0 z-0 hidden w-2/4 lg:block -top-64'
							alt='Un menum Hamburger avec frite et boisson'
						/>

						<div className='absolute hidden w-40 h-40 p-3 rounded-full lg:block top-40 lg:right-80 bg-danger-primary'>
							<div className='flex items-center justify-center w-full h-full text-white border-2 border-white border-dashed rounded-full'>
								<div className='text-center'>
									<span className='block font-extrabold'>
										<span className='text-5xl'>5</span>
										<span className='text-2xl'>.49€</span>
									</span>
									<span className='text-sm tracking-widest uppercase'>
										seulement
									</span>
								</div>
							</div>
						</div>

						<div className='relative z-10 text-center uppercase lg:text-left text-secondary'>
							<h1 className='mb-6 text-sm font-semibold md:text-base'>
								C'est le moment de gouter au bon goût des hamburgers.
							</h1>
							<h2 className='font-secondary shadowTitleSecondary'>
								<span className='block text-6xl md:text-8xl sm:text-left'>
									Burger
								</span>
								<span className='block text-6xl sm:inline-block'>House</span>
								<span className='ml-0 text-2xl sm:ml-3 md:text-4xl'>
									CLick <span className='text-danger-primary'>&</span> Collect
								</span>
							</h2>
						</div>
					</div>
					{/* <Button
						className='mx-24 mt-5 text-center lg:inline-block md:inline-block sm:mx-56 md:mx-64'
						color='secondary'
						theme='big'
					>
						Créer mon compte
					</Button> */}
				</Container>
			</div>
		</div>
	);
}
