import { EnvelopeIcon, MapPinIcon } from "@heroicons/react/24/solid";
import React from "react";
import Background from "../../../medias/images/background/bg-footer.jpg";
import FacebookIcon from "../../../medias/svg/facebook-f-brands-solid.svg";
import InstagramIcon from "../../../medias/svg/instagram-brands-solid.svg";
import Logo from "../../../medias/svg/Logo-burger-house-white.svg";
import PictoFooter from "../../../medias/svg/picto-footer-burger-house.svg";
import TweeterIcon from "../../../medias/svg/twitter-brands-solid.svg";
import WhattsappIcon from "../../../medias/svg/whatsapp-brands-solid.svg";
import Container from "../../elements/Container";

export default function Footer() {
	return (
		<Container>
			<div className='relative p-5 my-3 text-white md:my-10 lg:my-20'>
				<img
					src={Background}
					alt='Un plan de travail de cuisine où on conçoit un hamburger'
					className='absolute top-0 left-0 z-0 object-cover w-full h-full bg-gray-800'
				/>
				<div className='relative z-10 grid grid-cols-1 md:grid-cols-2'>
					<div className='hidden w-full md:p-10 md:mt-10 md:block'>
						<img
							src={Logo}
							alt='Logo Burger House'
							className='w-1/2 text-center md:w-2/3'
						/>
						<p className='mt-8 md:mt-16'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel
							massa sed lectus bibendum cursus sit amet id elit. Sed mollis nibh
							vel sagittis porta. Nullam eu eros elit. Etiam finibus, arcu in
							blandit posuere, urna ex aliquet arcu, non pretium massa diam
							pellentesque lacus.
						</p>
					</div>

					<div className='flex flex-col items-center justify-center w-full mb-3'>
						<div className='flex items-center my-6'>
							<MapPinIcon className='w-8 h-8 text-primary' />
							<span className='ml-1 text-base font-extrabold tracking-normal uppercase md:text-lg'>
								15 place Bellecour, 69000 Lyon
							</span>
						</div>

						<div className='flex items-center'>
							<EnvelopeIcon className='w-8 h-8 text-primary' />
							<span className='ml-3 text-base font-extrabold tracking-normal uppercase md:text-lg'>
								info@burger-house.com
							</span>
						</div>
					</div>
				</div>

				<div className='relative z-10 flex items-center justify-between w-full mt-10'>
					<div className='text-sm font-extrabold tracking-tighter uppercase'>
						<div className='flex items-center mr-2 justify-items-center text-primary'>
							<img
								src={PictoFooter}
								alt='pictogramme Burger House'
								className='flex items-center w-6 mr-3'
							/>
							Burger House
						</div>
						<div className='flex items-baseline'>
							<span className='relative text-xs font-light tracking-tighter normal-case -bottom-1'>
								all rights reserved <span className='ml-2 text-sm'>©</span> 2024
							</span>
						</div>
					</div>
					<div className='flex'>
						<button href='#' className='ml-1.5 bg-white rounded-full group'>
							<div className='z-0 p-3 rounded-full group-hover:bg-primary animate'>
								<img
									src={InstagramIcon}
									alt='icone pour Instagram'
									className='z-10 w-5 h-5 rounded-full hover:bg-primary animate'
								/>
							</div>
						</button>

						<button href='#' className='ml-1.5 bg-white rounded-full group'>
							<div className='z-0 p-3 rounded-full group-hover:bg-primary animate'>
								<img
									src={FacebookIcon}
									alt='icone pour Facebook'
									className='z-10 w-5 h-5 rounded-full hover:bg-primary animate'
								/>
							</div>
						</button>

						<button href='#' className='ml-1.5 bg-white rounded-full group'>
							<div className='z-0 p-3 rounded-full group-hover:bg-primary animate'>
								<img
									src={TweeterIcon}
									alt='icone pour Tweeter'
									className='z-10 w-5 h-5 rounded-full hover:bg-primary animate'
								/>
							</div>
						</button>

						<button href='#' className='ml-1.5 bg-white rounded-full group'>
							<div className='z-0 p-3 rounded-full group-hover:bg-primary animate'>
								<img
									src={WhattsappIcon}
									alt='icone pour Whattsappn'
									className='z-10 w-5 h-5 rounded-full hover:bg-primary animate'
								/>
							</div>
						</button>
					</div>
				</div>
			</div>
		</Container>
	);
}
