import React from "react";
import LastProduct1 from "../../../medias/images/products/LastProducts-1.jpg";
import LastProduct2 from "../../../medias/images/products/LastProducts-2.jpg";
import LastProduct3 from "../../../medias/images/products/LastProducts-3.jpg";
import Container from "../../elements/Container";

export default function LastProducts() {
	return (
		<Container>
			<div className='grid w-full grid-flow-row grid-rows-3 gap-3 my-20 text-white uppercase md:grid-rows-2 md:grid-cols-2 lg:gap-9 lg:px-11 h-96 lg:h-96 sm:h-full'>
				<div className='relative h-full p-5 rounded-md md:row-span-2 bg-primary sm:p-16 lg:p-7'>
					<img
						src={LastProduct1}
						className='absolute top-0 left-0 z-0 object-cover w-full h-full rounded-md'
						alt='Un délicieux hamburger exposé sur une pièce de bois'
					/>
					<div className='relative z-10'>
						<span className='block text-2xl font-bold text-primary'>
							Killer Burger
						</span>
						<span className='text-lg font-black md:text-1xl'>
							Le plus populaire
						</span>
						<span className='block text-lg font-black md:text-1xl'>
							Plus de plaisir
						</span>
					</div>
				</div>

				<div className='relative h-full p-5 rounded-md bg-primary sm:p-16 lg:p-7'>
					<img
						src={LastProduct2}
						className='absolute top-0 left-0 z-0 object-cover w-full h-full rounded-md'
						alt='Un délicieux hamburger exposé sur une pièce de bois'
					/>
					<div className='relative z-10'>
						<span className='block text-2xl font-bold text-primary'>
							Island Burger
						</span>
						<span className='text-lg font-black md:text-1xl'>Plus de goût</span>
					</div>
				</div>

				<div className='relative h-full p-5 rounded-md bg-primary sm:p-16 lg:p-7'>
					<img
						src={LastProduct3}
						className='absolute top-0 left-0 z-0 object-cover w-full h-full rounded-md'
						alt='Un délicieux hamburger exposé sur une pièce de bois'
					/>
					<div className='relative z-10'>
						<span className='block text-2xl font-bold text-primary'>
							Orlando's Burger
						</span>
						<span className='text-lg font-black md:text-1xl'>
							Frais & pimenté
						</span>
					</div>
				</div>
			</div>
		</Container>
	);
}
