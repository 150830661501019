import React from "react";

export default function Button({ children, className, color, theme }) {
	let background;

	switch (color) {
		case "secondary":
			background = "bg-secondary hover:bg-secondary-hover";
			break;
		case "danger-primary":
			background = "bg-danger-primary hover:bg-danger-primary-hover";
			break;
		default:
			background = "bg-primary hover:bg-primary-hover";
			break;
	}

	switch (theme) {
		case "small":
			return (
				<div
					className={`${className} ${background} px-4 py-2 text-xs font-medium text-white uppercase shadow-xl animate`}
				>
					{children}
				</div>
			);
		case "big":
			return (
				<div
					className={`${className} ${background} px-4 py-3 md:px-8 md:py-4 text-sm md:text-base font-medium text-white uppercase shadow-xl animate`}
				>
					{children}
				</div>
			);
		default:
			return (
				<div
					className={`${className} ${background} px-4 py-3 text-xs md:text-sm font-medium text-white uppercase shadow-xl animate`}
				>
					{children}
				</div>
			);
	}
}
