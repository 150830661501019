import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "../../../css/carousel.css";
import Event1 from "../../../medias/images/events/event-1.jpg";
import Event2 from "../../../medias/images/products/Product-2.jpg";
import Event3 from "../../../medias/images/products/Product-3.jpg";
import Container from "../../elements/Container";
import Heading from "../../elements/displayTitles/Heading";

const Slide = ({ children, category, title, image, alt }) => {
	return (
		<div className='grid p-3 md:p-6 md:grid-cols-2'>
			<div className='p-5 md:p-10 md:mb-10'>
				<Heading variant='h4'>{category}</Heading>
				<Heading variant='h3' theme='secondary' className='my-5'>
					{title}
				</Heading>
				<p className='text-lg font-light tracking-wide text-gray-800'>
					{children}
				</p>
			</div>
			<div className='relative w-full h-96 md:h-full'>
				<img
					src={image}
					alt={alt}
					className='absolute top-0 left-0 z-0 object-cover w-full h-full bg-primary'
				/>
			</div>
		</div>
	);
};

export default function Event() {
	const items = [
		<Slide
			image={Event1}
			category='évènement à venir'
			title='Vivez la demi-finale'
			alt='Description de la photo 1'
		>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut
			est vel lectus tristique pharetra. Nam porta nisi orci, imperdiet
			pellentesque metus euismod ac. Morbi scelerisque augue in mi blandit, sed
			viverra tortor interdum.
		</Slide>,
		<Slide
			image={Event2}
			category='Nouveau burger'
			title='Découvrez le killer burger'
			alt='Description de la photo 2'
		>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut
			est vel lectus tristique pharetra. Nam porta nisi orci, imperdiet
			pellentesque metus euismod ac. Morbi scelerisque augue in mi blandit, sed
			viverra tortor interdum.
		</Slide>,
		<Slide
			image={Event3}
			category='Nouvelle recette'
			title='Le Végéburger arrive'
			alt='Description de la photo 3'
		>
			Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse ut
			est vel lectus tristique pharetra. Nam porta nisi orci, imperdiet
			pellentesque metus euismod ac. Morbi scelerisque augue in mi blandit, sed
			viverra tortor interdum.
		</Slide>,
	];

	return (
		<Container>
			<div className='mb-20 border border-gray-100 shadow-2xl'>
				<AliceCarousel
					mouseTracking
					items={items}
					autoplay
					autoPlayInterval='2000'
					infinite
					disableButtonsControls
					animationType='fadeout'
				/>
			</div>
		</Container>
	);
}
