import React from "react";
import Product1 from "../../../medias/images/products/Product-1.jpg";
import Product2 from "../../../medias/images/products/Product-2.jpg";
import Product3 from "../../../medias/images/products/Product-3.jpg";
import Button from "../../elements/Button";
import Container from "../../elements/Container";
import Heading from "../../elements/displayTitles/Heading";
import HeadingTitles from "../../elements/displayTitles/HeadingTitles";

export default function Products() {
	return (
		<Container>
			<HeadingTitles>Toujours des délicieux burgers</HeadingTitles>

			<Heading theme='secondary' alignement='center' className='my-5'>
				Choisissez et savourez
			</Heading>

			<p className='text-center'>
				Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel massa
				sed lectus bibendum cursus sit amet id elit. Sed mollis nibh vel
				sagittis porta. Nullam eu eros elit. Etiam finibus, arcu in blandit
				posuere, urna ex aliquet arcu, non pretium massa diam pellentesque
				lacus.
			</p>

			<div className='grid grid-cols-1 my-16 md:grid-cols-3 gap-x-1'>
				<div className='w-full h-full'>
					<div className='relative w-full h-72 sm:w-2/3 sm:mx-auto md:w-full'>
						<img
							src={Product1}
							className='absolute top-0 left-0 z-0 object-cover w-full h-full bg-gray-100'
							alt='Un délicieux hamburger'
						/>
					</div>
					<div className='flex flex-col items-center justify-center px-5 pb-5'>
						<Heading variant='h3' className='my-5'>
							Lorem Ipsum Dolor
						</Heading>
						<p className='text-center'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						</p>
						<Button
							className='font-secondary'
							color='danger-primary'
							theme='big'
						>
							Commander
						</Button>
					</div>
				</div>

				<div className='w-full h-full my-10 md:my-0'>
					<div className='relative w-full h-72 sm:w-2/3 sm:mx-auto md:w-full'>
						<img
							src={Product2}
							className='absolute top-0 left-0 z-0 object-cover w-full h-full bg-gray-100'
							alt='Un délicieux hamburger'
						/>
					</div>
					<div className='flex flex-col items-center justify-center px-5 pb-5'>
						<Heading variant='h3' className='my-5'>
							Lorem Ipsum Dolor
						</Heading>
						<p className='text-center'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						</p>
						<Button
							className='font-secondary'
							color='danger-primary'
							theme='big'
						>
							Commander
						</Button>
					</div>
				</div>

				<div className='w-full h-full'>
					<div className='relative w-full h-72 sm:w-2/3 sm:mx-auto md:w-full'>
						<img
							src={Product3}
							className='absolute top-0 left-0 z-0 object-cover w-full h-full bg-gray-100'
							alt='Un délicieux hamburger'
						/>
					</div>
					<div className='flex flex-col items-center justify-center px-5 pb-5'>
						<Heading variant='h3' className='my-5'>
							Lorem Ipsum Dolor
						</Heading>
						<p className='text-center'>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
						</p>
						<Button
							className='font-secondary'
							color='danger-primary'
							theme='big'
						>
							Commander
						</Button>
					</div>
				</div>
			</div>
		</Container>
	);
}
