import React from 'react'
import Router from './Router.js';

export default function App() {
  return (
    <div>
      <Router />
    </div>
  )
}

