import React from "react";
import Assiette from "../../../medias/images/background/Assiette-burger-frites.png";
import Burger from "../../../medias/images/background/Burger.png";
import Sauce from "../../../medias/images/background/Sauce-pimente.png";
import Container from "../../elements/Container";
import Heading from "../../elements/displayTitles/Heading";

export default function Booking() {
	return (
		<Container>
			<div className='relative mb-10'>
				<img
					src={Burger}
					alt='Un hamburger'
					className='absolute z-10 hidden -top-10 -left-36 sm:block'
				/>
				<img
					src={Sauce}
					alt='Une sauce pimentée'
					className='absolute left-0 z-0 hidden -bottom-20 lg:block'
				/>
				<img
					src={Assiette}
					alt='Une assiette avec un hamburger et des frites'
					className='absolute z-20 hidden top-28 -right-96 lg:block'
				/>
				<div className='max-w-3xl pb-2 mx-auto sm:px-10 pt-14'>
					<Heading variant='h3' alignement='center'>
						Réservation
					</Heading>
					<Heading theme='secondary' alignement='center' className='my-5'>
						Réserver votre table
					</Heading>
					<form className='grid grid-cols-1 my-10 sm:grid-cols-2 gap-x-7 gap-y-6'>
						<div>
							<label htmlFor='name'>Nom</label>
							<input
								type='text'
								name='name'
								id='name'
								required
								autoComplete='off'
								className='block w-full p-5 border-gray-400 rounded-md focus:ring-primary focus:border-primary focus:border'
								placeholder='John Doe'
							/>
						</div>

						<div>
							<label htmlFor='name'>Email</label>
							<input
								type='email'
								name='email'
								id='email'
								required
								autoComplete='off'
								className='block w-full p-5 border-gray-400 rounded-md focus:ring-primary focus:border-primary focus:border'
								placeholder='John.doe@email.com'
							/>
						</div>

						<div>
							<label htmlFor='name'>Date</label>
							<input
								type='date'
								name='date'
								id='date'
								required
								autoComplete='off'
								className='block w-full p-5 border-gray-400 rounded-md focus:ring-primary focus:border-primary focus:border'
								placeholder='Date'
							/>
						</div>

						<div>
							<label htmlFor='name'>Heure</label>
							<input
								type='time'
								name='time'
								id='time'
								required
								min='12:00'
								max='23:00'
								autoComplete='off'
								className='block w-full p-5 border-gray-400 rounded-md focus:ring-primary focus:border-primary focus:border'
								placeholder='19:30'
							/>
						</div>

						<div>
							<label htmlFor='name'>Nombre de personnes</label>
							<input
								type='number'
								name='customer'
								id='customer'
								required
								min='1'
								max='10'
								autoComplete='off'
								className='block w-full p-5 border-gray-400 rounded-md focus:ring-primary focus:border-primary focus:border'
								placeholder='Nombre de personnes'
							/>
						</div>

						<div>
							<label htmlFor='name' className='invisible'>
								Réserver
							</label>
							<input
								type='button'
								name='submit'
								id='submit'
								className='w-full p-5 tracking-widest text-white uppercase rounded-md cursor-pointer font-secondary bg-danger-primary hover:bg-danger-primary-hover animate'
								value='Trouver une table'
							/>
						</div>
					</form>
				</div>
			</div>
		</Container>
	);
}
